import React from "react";
import { Container } from "react-bootstrap";

function Background() {
  return (
    <Container id="background" className="mr-5" style={{ textAlign: "left" }}>
      <div className="d-none d-md-block" style={{ height: "5rem" }}></div>
      <p className="lead">
        Greetings! I'm Thomas.
        <br />
        <br />
        After graduation In 2018, I started my career as a system engineer in a
        <a className="" href="https://sg.micron.com/">
          {" "}
          semiconductor factory
        </a>
        . The intricate world of IT-systems fascinated me, setting the stage for
        my subsequent role as an Automation QA Engineer at
        <a href="https://shopee.com/"> e-commerce </a>
        and
        <a href="https://indeed.com/"> job-seeking </a>platforms. Impacted by a
        layoff in 2023, I seized the opportunity to return to school, taking up
        <a href="https://www.iss.nus.edu.sg/graduate-programmes/programme/detail/graduate-diploma-in-systems-analysis">
          {" "}
          GDipSA at NUS-ISS
        </a>
        . Currently the course is at its end and I am interning at
        <a href="https://www.oracle.com/sg/cloud/">
          {" "}
          Oracle Cloud Infrastructure.
        </a>
        <br />
        <br />
      </p>
    </Container>
  );
}

export default Background;
